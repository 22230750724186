import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import {compose, setPropTypes, onlyUpdateForPropTypes} from 'recompose';
import config from '@p14/config';

export const Root = styled.aside`
  top: 0;
  padding: 70px 20px 20px;
  left: 276px;
  width: 276px;
  height: 100%;
  z-index: 1000;
  position: absolute;
  text-align: center;
  background-color: rgba(27, 35, 51, 0.76);

  @media ${config.media.mobile} {
    left: 0;
    width: 100%;
    padding: 20px;
    display: ${props => props.activeView === 'map' ? 'none' : 'block'};
  }
`;

export const Title = styled.h2`
  font-size: 18px;
  line-height: 23px;
  margin: 0;
  padding: 0 10px;
  text-transform: uppercase;
  color: ${config.colors.main};
  font-family: ${config.fonts.serif};
`;

export const Address = styled.address`
  color: ${config.colors.light};
  font-family: ${config.fonts.sansSerif};
  font-size: 14px;
  margin: 6px 0;
  font-style: normal;
  display: block;
`;

export const Time = styled.time`
  color: ${config.colors.light};
  font-family: ${config.fonts.serif};
  font-size: 14px;
  margin: 0 0 65px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 0.05em;
  display: block;
`;

export const Text = styled.p`
  margin: 0;
  font-size: 12px;
  opacity: 0.8;
  text-align: left;
  line-height: 18px;
  color: ${config.colors.light};
  font-family: ${config.fonts.sansSerif};
`;

export const Button = styled.button`
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 0;
  width: 20px;
  height: 20px;
  outline: none;
  background-color: transparent;
  border: none;

  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background-color: ${config.colors.light};
  }

  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;

export const Description = ({activeView, title, address, time, text, onClose}) => (
  <Root activeView={activeView}>
    <Button type="button" onClick={onClose}/>
    <Title>{title}</Title>
    <Address>{address}</Address>
    <Time>{time}</Time>
    <Text>{text}</Text>
  </Root>
);

export const enhance = compose(
  onlyUpdateForPropTypes,
  setPropTypes({
    activeView: T.oneOf(['map', 'list']).isRequired,
    title: T.string.isRequired,
    address: T.string.isRequired,
    time: T.string.isRequired,
    text: T.string.isRequired,
    onClose: T.func.isRequired,
  }),
);

export default enhance(Description);
