// External dependencies
import React from 'react';
import {connect} from 'react-redux';

// Internal dependencies
import config from '@p14/config';
export const TRANSLATION_NOT_FOUND = 'TRANSLATION NOT FOUND';

export function translator(dict, lang) {
  return function(str) {
    const defaultLangDict = dict[config.defaultLang] || {};
    const currentLangDict = dict[lang] || {};

    if (currentLangDict[str] !== undefined)
      return currentLangDict[str];

    if (defaultLangDict[str] !== undefined)
      return defaultLangDict[str];

    console.log(`Translation not found for ${str}`);
    return TRANSLATION_NOT_FOUND;
  }
}

export function withTranslation(dict) {
  return function(Component) {
    class WithTranslation extends React.PureComponent {
      static displayName = `WithTranslation(${Component.name})`;

      componentWillMount() {
        this.tr = translator(dict, this.props.lang);
      }

      componentWillUpdate(nextProps) {
        if (this.props.lang !== nextProps.lang) {
          this.tr = translator(dict, nextProps.lang);
        }
      }

      render() {
        const {lang, ...props} = this.props;
        return <Component {...props} tr={this.tr} />
      }
    }

    return connect(mapStateToProps)(WithTranslation);
  }
}

const mapStateToProps = state => {
  return {lang: state.locale};
};

export default withTranslation;
