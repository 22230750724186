// External dependencies
import React from 'react';

export function withTouch(id) {
  return function(Component) {
    class withTouch extends React.PureComponent {
      static displayName = `withTouch(${Component.name})`;

      clientXDown = 0;
      clientYDown = 0;
      state = {followedX: 0, followedY: 0};
      $root = null

      componentDidMount() {
        this.$root = document.getElementById(id);
        window.addEventListener('touchmove', this.handleTouchMove, false);
        window.addEventListener('mousemove', this.handleMouseMove, false);
        window.addEventListener('click', this.handleMoveEnd, false);
        this.$root.addEventListener('mousedown', this.handleMouseDown, {passive: true});
        this.$root.addEventListener('touchstart', this.handleTouchStart, {passive: true});
        this.$root.addEventListener('touchend', this.handleMoveEnd, false);
      }
      componentWillUnmount() {
        window.removeEventListener('touchmove', this.handleTouchMove, false);
        window.removeEventListener('mousemove', this.handleMouseMove, false);
        window.removeEventListener('click', this.handleMoveEnd, false);
        this.$root.removeEventListener('mousedown', this.handleMouseDown, {passive: true});
        this.$root.removeEventListener('touchstart', this.handleTouchStart, {passive: true});
        this.$root.removeEventListener('touchend', this.handleMoveEnd, false);
      }
      handleMouseDown = e => this.handleMoveStart(e.clientX, e.clientY, e)
      handleTouchStart = e => this.handleMoveStart(e.touches[0].clientX, e.touches[0].clientY, e)

      handleTouchMove = e => this.handleMove(e.touches[0].clientX, e.touches[0].clientY, e)
      handleMouseMove = e => this.handleMove(e.clientX, e.clientY, e)
      handleMoveEnd = e => {
        const {followedX, followedY} = this.state;

        if (followedX !== 0 || followedY !== 0) {
          e.preventDefault();
          e.stopPropagation();
        }

        this.clientXDown = undefined;
        this.clientYDown = undefined;

        if (this.props.onFollowTouchEnd) {
          this.props.onFollowTouchEnd(followedX, followedY);
        }

        this.setState({followedX: 0, followedY: 0});
      }
      handleMoveStart = (clientXDown, clientYDown) => {
        this.clientXDown = clientXDown;
        this.clientYDown = clientYDown;
      }
      handleMove = (clientX, clientY, e) => {
        const clientXDown = this.clientXDown;
        const clientYDown = this.clientYDown;
        const $root = document.getElementById(id);

        if (clientXDown && clientYDown && $root) {
          const xDiff = clientXDown - clientX;
          const yDiff = clientYDown - clientY;

          if (xDiff && yDiff) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({followedX: xDiff / $root.offsetWidth * 100, followedY: yDiff / $root.offsetHeight * 100});
          } else {
            this.setState({followedX: 0, followedY: 0});
          }
        }
      }

      render() {
        const {followedX, followedY} = this.state;

        return (
          <Component {...this.props} followedX={followedX} followedY={followedY}/>
        );
      }
    }

    return withTouch;
  }
};

export default withTouch;
