export default {
  ru: {
    title: 'Хамовники – один из немногих районов столицы, где&nbsp;большая&nbsp;часть семей живут уже не первое поколение.',
    map: 'Карта',
    list: 'Список',
    radios: [{
      label: 'Все места',
      value: 'all',
    }, {
      label: 'Спорт и отдых',
      value: 'sport',
    }, {
      label: 'Рестораны',
      value: 'restaurant',
    }, {
      label: 'Культура',
      value: 'culture',
    }, {
      label: 'Ночная жизнь',
      value: 'nightlife',
    }],
    pins: [{
      id: 1,
      title: 'Усачевский рынок',
      address: 'Усачевский рынок',
      time: '1 минута пешком',
      lat: 55.727485,
      lng: 37.5656802,
      type: 'restaurant',
      text: 'Один из старейших рынков Москвы полностью обновился и стал идеальным местом для гастрономических прогулок.',
    }, {
      id: 2,
      title: 'Раковая на Усачева',
      address: 'Усачева, 22',
      time: '7 минут пешком',
      lat: 55.727724,
      lng: 37.5678142,
      type: 'restaurant',
      text: 'Гастробар от создателей «Раковой на Тишинке»',
    }, {
      id: 3,
      title: 'Black market',
      address: 'Усачева, 2, с. 1',
      time: '11 минут пешком',
      lat: 55.730925,
      lng: 37.5744633,
      type: 'restaurant',
      text: 'Лучшие воскресные бранчи.',
    }, {
      id: 4,
      title: 'Shaggy dog',
      address: 'Льва Толстого, 5/1',
      time: '20 минут пешком',
      lat: 55.732214,
      lng: 37.5867328,
      type: 'restaurant',
      text: 'Место, куда не просто пускают с собаками, а еще и будут рады.',
    }, {
      id: 5,
      title: 'MØS',
      address: 'Трубецкая, 10',
      time: '12 минут пешком',
      lat: 55.7272391,
      lng: 37.5735094,
      type: 'restaurant',
      text: 'Гастробар качественной скандинавской кухни.',
    }, {
      id: 6,
      title: 'Голубка',
      address: 'Большая Пироговская, 53/55',
      time: '5 минут пешком',
      lat: 55.7274294,
      lng: 37.559025,
      type: 'restaurant',
      text: 'Для обеда после долгой прогулки вокруг Новодевичьего монастыря.',
    }, {
      id: 7,
      title: 'Бассейн “Чайка”',
      address: 'Турчанинов пер., 3, стр. 1',
      time: '30 минут пешком',
      lat: 55.7365059,
      lng: 37.5941853,
      type: 'sport',
      text: 'Легендарный бассейн под открытым небом, который мы особенно любим зимой.',
    }, {
      id: 8,
      title: 'Беговой клуб в Парке Горького',
      address: 'Парк Горького',
      time: '30 минут пешком',
      lat: 55.7299141,
      lng: 37.6001742,
      type: 'sport',
      text: 'Если вам нужно поставить технику бега или просто нужна компания.',
    }, {
      id: 9,
      title: 'Лужники',
      address: 'Лужники',
      time: '25 минут пешком',
      lat: 55.715765,
      lng: 37.551527,
      type: 'sport',
      text: 'Совместные тренировки на открытых площадках, пинг-понг и даже “Академия бокса”',
    }, {
      id: 10,
      title: 'Усадьба Трубецких',
      address: 'Усадьба Трубецких',
      time: '10 минут пешком',
      lat: 55.727853,
      lng: 37.5748161,
      type: 'sport',
      text: 'Не только парк с горбатыми мостиками, но и центр дворянского спорта: здесь можно записаться в клуб верховой езды.',
    }, {
      id: 11,
      title: 'Центр документального кино',
      address: 'Зубовский б-р, 2, строение 7',
      time: '27 минут пешком',
      lat: 55.7363276,
      lng: 37.5920576,
      type: 'culture',
      text: 'Показы редких фильмов и приятная атмосфера.',
    }, {
      id: 12,
      title: 'Музей Москвы',
      address: 'Зубовский б-р, 2',
      time: '27 минут пешком',
      lat: 55.7366891,
      lng: 37.5896676,
      type: 'culture',
      text: 'Вся история столицы в современных и совсем не нафталиновых форматах, детям наверняка понравится.',
    }, {
      id: 13,
      title: 'Усадьба Льва Толстого',
      address: 'Льва Толстого, 21',
      time: '20 минут пешком',
      lat: 55.734249,
      lng: 37.5836694,
      type: 'culture',
      text: 'Место, где легко забыть о том, что вы в центре города – теперь ваш “домашний” музей.',
    }, {
      id: 14,
      title: 'Музей ар-деко',
      address: 'Лужнецкая наб., 2/4, стр. 4',
      time: '28 минут пешком',
      lat: 55.715489,
      lng: 37.5715743,
      type: 'culture',
      text: 'Скульптуры, декоративные панно и предметы интерьера из “золотых двадцатых”.',
    }, {
      id: 15,
      title: 'Новодевичий монастырь',
      address: 'Новодевичий монастырь',
      time: '13 минут пешком',
      lat: 55.726203,
      lng: 37.5542111,
      type: 'culture',
      text: 'Ансамбль Новодевичьего монастыря включен в список Всемирного наследия ЮНЕСКО, строительство началось в 1524 году. ',
    }, {
      id: 16,
      title: 'Winil Wine Bar',
      address: 'Зубовская ул., 5/36',
      time: '17 минут пешком',
      lat: 55.7374583,
      lng: 37.5824311,
      type: 'nightlife',
      text: 'Винный бар с дегустационными сетами для исследователей.',
    }, {
      id: 17,
      title: 'ЛУЧ',
      address: 'Большая Пироговская ул., 27',
      time: '5 минут пешком',
      lat: 55.730197,
      lng: 37.5656544,
      type: 'nightlife',
      text: 'Легендарный бар с самой длинной стойкой в Москве и арт-инсталляциями.',
    }, {
      id: 18,
      title: 'SOHO ROOMS',
      address: 'Саввинская наб, 12/8',
      time: '15 минут пешком',
      lat: 55.7322955,
      lng: 37.55832,
      type: 'nightlife',
      text: 'Клуб, создающий вечную ночную пробку на набережной, и не зря.',
    }],
  },

  en: {
    title: 'Хамовники – один из немногих районов столицы, где&nbsp;большая&nbsp;часть семей живут уже не первое поколение.',
    radios: [{
      label: 'Все места',
      value: 'all',
    }, {
      label: 'Спорт и отдых',
      value: 'sport',
    }, {
      label: 'Рестораны',
      value: 'restaurant',
    }, {
      label: 'Культура',
      value: 'culture',
    }, {
      label: 'Ночная жизнь',
      value: 'nightlife',
    }],
    pins: [{
      id: 1,
      title: 'Усачевский рынок',
      address: 'Усачевский рынок',
      time: '1 минута пешком',
      lat: 55.727485,
      lng: 37.5656802,
      type: 'restaurant',
      text: 'Один из старейших рынков Москвы полностью обновился и стал идеальным местом для гастрономических прогулок.',
    }, {
      id: 2,
      title: 'Раковая на Усачева',
      address: 'Усачева, 22',
      time: '7 минут пешком',
      lat: 55.727724,
      lng: 37.5678142,
      type: 'restaurant',
      text: 'Гастробар от создателей «Раковой на Тишинке»',
    }, {
      id: 3,
      title: 'Black market',
      address: 'Усачева, 2, с. 1',
      time: '11 минут пешком',
      lat: 55.730925,
      lng: 37.5744633,
      type: 'restaurant',
      text: 'Лучшие воскресные бранчи.',
    }, {
      id: 4,
      title: 'Shaggy dog',
      address: 'Льва Толстого, 5/1',
      time: '20 минут пешком',
      lat: 55.732214,
      lng: 37.5867328,
      type: 'restaurant',
      text: 'Место, куда не просто пускают с собаками, а еще и будут рады.',
    }, {
      id: 5,
      title: 'MØS',
      address: 'Трубецкая, 10',
      time: '12 минут пешком',
      lat: 55.7272391,
      lng: 37.5735094,
      type: 'restaurant',
      text: 'Гастробар качественной скандинавской кухни.',
    }, {
      id: 6,
      title: 'Голубка',
      address: 'Большая Пироговская, 53/55',
      time: '5 минут пешком',
      lat: 55.7274294,
      lng: 37.559025,
      type: 'restaurant',
      text: 'Для обеда после долгой прогулки вокруг Новодевичьего монастыря.',
    }, {
      id: 7,
      title: 'Бассейн “Чайка”',
      address: 'Турчанинов пер., 3, стр. 1',
      time: '30 минут пешком',
      lat: 55.7365059,
      lng: 37.5941853,
      type: 'sport',
      text: 'Легендарный бассейн под открытым небом, который мы особенно любим зимой.',
    }, {
      id: 8,
      title: 'Беговой клуб в Парке Горького',
      address: 'Парк Горького',
      time: '30 минут пешком',
      lat: 55.7299141,
      lng: 37.6001742,
      type: 'sport',
      text: 'Если вам нужно поставить технику бега или просто нужна компания.',
    }, {
      id: 9,
      title: 'Лужники',
      address: 'Лужники',
      time: '25 минут пешком',
      lat: 55.715765,
      lng: 37.551527,
      type: 'sport',
      text: 'Совместные тренировки на открытых площадках, пинг-понг и даже “Академия бокса”',
    }, {
      id: 10,
      title: 'Усадьба Трубецких',
      address: 'Усадьба Трубецких',
      time: '10 минут пешком',
      lat: 55.727853,
      lng: 37.5748161,
      type: 'sport',
      text: 'Не только парк с горбатыми мостиками, но и центр дворянского спорта: здесь можно записаться в клуб верховой езды.',
    }, {
      id: 11,
      title: 'Центр документального кино',
      address: 'Зубовский б-р, 2, строение 7',
      time: '27 минут пешком',
      lat: 55.7363276,
      lng: 37.5920576,
      type: 'culture',
      text: 'Показы редких фильмов и приятная атмосфера.',
    }, {
      id: 12,
      title: 'Музей Москвы',
      address: 'Зубовский б-р, 2',
      time: '27 минут пешком',
      lat: 55.7366891,
      lng: 37.5896676,
      type: 'culture',
      text: 'Вся история столицы в современных и совсем не нафталиновых форматах, детям наверняка понравится.',
    }, {
      id: 13,
      title: 'Усадьба Льва Толстого',
      address: 'Льва Толстого, 21',
      time: '20 минут пешком',
      lat: 55.734249,
      lng: 37.5836694,
      type: 'culture',
      text: 'Место, где легко забыть о том, что вы в центре города – теперь ваш “домашний” музей.',
    }, {
      id: 14,
      title: 'Музей ар-деко',
      address: 'Лужнецкая наб., 2/4, стр. 4',
      time: '28 минут пешком',
      lat: 55.715489,
      lng: 37.5715743,
      type: 'culture',
      text: 'Скульптуры, декоративные панно и предметы интерьера из “золотых двадцатых”.',
    }, {
      id: 15,
      title: 'Новодевичий монастырь',
      address: 'Новодевичий монастырь',
      time: '13 минут пешком',
      lat: 55.726203,
      lng: 37.5542111,
      type: 'culture',
      text: 'Ансамбль Новодевичьего монастыря включен в список Всемирного наследия ЮНЕСКО, строительство началось в 1524 году. ',
    }, {
      id: 16,
      title: 'Winil Wine Bar',
      address: 'Зубовская ул., 5/36',
      time: '17 минут пешком',
      lat: 55.7374583,
      lng: 37.5824311,
      type: 'nightlife',
      text: 'Винный бар с дегустационными сетами для исследователей.',
    }, {
      id: 17,
      title: 'ЛУЧ',
      address: 'Большая Пироговская ул., 27',
      time: '5 минут пешком',
      lat: 55.730197,
      lng: 37.5656544,
      type: 'nightlife',
      text: 'Легендарный бар с самой длинной стойкой в Москве и арт-инсталляциями.',
    }, {
      id: 18,
      title: 'SOHO ROOMS',
      address: 'Саввинская наб, 12/8',
      time: '15 минут пешком',
      lat: 55.7322955,
      lng: 37.55832,
      type: 'nightlife',
      text: 'Клуб, создающий вечную ночную пробку на набережной, и не зря.',
    }],
  },
};
