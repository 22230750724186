import React from 'react';
import styled from 'styled-components';
import T from 'prop-types';
import {compose, withState, onlyUpdateForPropTypes, setPropTypes} from 'recompose';
import GoogleMapReact from 'google-map-react';
import Layout from '@p14/layouts';
import * as Components from '@p14/components';
import * as Modules from './_';
import * as HOC from '@p14/HOC';
import config from '@p14/config';
import data from '@p14/texts/map';

export const Root = styled.section``;

export const Container = styled.div`
  width: 100%;
  height: 500px;
  overflow: hidden;
  position: relative;
  display: flex;
`;

export const Aside = styled.aside`
  width: 276px;
  flex: 0 0 276px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 20px 31px 0 37px;
  background: rgba(241, 242, 245, 0.54);

  @media ${config.media.mobile} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    padding: 20px;
    background-color: ${config.colors.light};
    border-top: 1px solid #ddd;
    display: ${props => props.isDescriptionShown || props.activeView === 'map' ? 'none' : 'block'}
  }
`;

export const Body = styled.div`
  flex: 1 1;
`;

export const Article = styled.article`
  border-bottom: 1px solid #ddd;
  padding-bottom: 14px;
  margin-bottom: 20px;
  cursor: pointer;

  &:hover h4 {
    color: ${config.colors.main};
  }
`;

export const Title = styled.h4`
  margin: 0 0 8px;
  font-family: Austin;
  font-size: 16px;
  color: ${props => props.isActive ? config.colors.main : config.colors.text};
  text-transform: uppercase;
  transition: color 0.3s ease 0s;
  will-change: color;
`;

export const Caption = styled.p`
  margin: 4px 0;
  font-family: Geometria;
  font-size: 14px;
  color: ${config.colors.text};
`;

export const Pin = styled.div`
  cursor: pointer;
`;

export const Form = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 53px auto 60px;

  @media ${config.media.mobile} {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 20px;
    margin: 20px auto;
  }
`;

export const View = styled.div`
  display: none;

  @media ${config.media.mobile} {
    height: 60px;
    width: 100%;
    display: flex;
  }
`;

export const Button = styled.button`
  width: 50%;
  height: 100%;
  display: block;
  outline: none;
  padding: 0;
  border: 1px solid ${config.colors.main};
  background-color: ${props => props.active ? config.colors.main : 'transparent'};
  transition: background-color 0.3s ease 0s;
  will-change: background-color;

  &:first-child {
    border-right: none;
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }
`;

export const Map = ({tr, center, activeView, activePinId, activeRadio, onRadioChange, onPinChange, onViewChange}) => (
  <Layout>
  <Root>
    <Components.Container>
      <Components.H1 style={{maxWidth: 705, marginLeft: 'auto', marginRight: 'auto'}} dangerouslySetInnerHTML={{__html: tr('title')}}/>
    </Components.Container>

    <Form>
      {tr('radios').map(radio => (
        <Modules.Radio
          {...radio}
          key={radio.value}
          isChecked={activeRadio === radio.value}
          onChange={onRadioChange}
          />
      ))}
    </Form>

    <View>
      <Button active={activeView === 'map'} type="button" onClick={() => onViewChange('map')}>{tr('map')}</Button>
      <Button active={activeView === 'list'} type="button" onClick={() => onViewChange('list')}>{tr('list')}</Button>
    </View>

    <Container>
      <Aside activeView={activeView} isDescriptionShown={tr('pins').filter(pin => activeRadio === 'all' ? pin : pin.type === activeRadio).find(pin => pin.id === activePinId)}>
        {tr('pins').filter(pin => activeRadio === 'all' ? pin : pin.type === activeRadio).map((pin, index) => (
          <Article key={index} onClick={() => onPinChange(pin.id)}>
            <Title isActive={pin.id === activePinId}>{pin.title}</Title>
            <Caption>{pin.address}</Caption>
            <Caption>{pin.time}</Caption>
          </Article>
        ))}
      </Aside>

      {tr('pins').filter(pin => activeRadio === 'all' ? pin : pin.type === activeRadio).find(pin => pin.id === activePinId) ? (
        <Modules.Description activeView={activeView} {...tr('pins').filter(pin => activeRadio === 'all' ? pin : pin.type === activeRadio).find(pin => pin.id === activePinId)} onClose={() => onPinChange(null)}/>
      ) : null}

      <Body>
        <GoogleMapReact
          defaultZoom={16}
          center={
            tr('pins').filter(pin => activeRadio === 'all' ? pin : pin.type === activeRadio).find(pin => pin.id === activePinId) ?
              tr('pins').filter(pin => activeRadio === 'all' ? pin : pin.type === activeRadio).find(pin => pin.id === activePinId) :
              center}
          >
            <Pin lat={55.7285} lng={37.5652063}>
              <svg viewBox="0 0 35 44" width="35" height="44">
                <g fill="none" fillRule="evenodd">
                  <path stroke="#231F20" strokeWidth=".5" fill="#FFF" strokeLinecap="round" strokeLinejoin="round" d="M17.617 43.637l-6.598-11.936 12.206-.426z"/>
                  <path d="M.364 17.028C.364 7.823 7.862.362 17.108.362c9.247 0 16.744 7.461 16.744 16.666 0 9.204-7.497 16.666-16.744 16.666-9.246 0-16.744-7.462-16.744-16.666z" stroke="#231F20" strokeWidth=".5" fill="#FFF" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M26.515 19.423l-.095.484c-.144.7-.76.786-1.198.786h-.884v.371h2.35v-1.641h-.173zm-11.611 4.245c-1.178 0-1.456-.177-1.456-.93V9.16H8.75v.372h.894c1.178 0 1.455.177 1.455.93v12.275c0 .753-.277.93-1.455.93H8.75v.372h7.047v-.372h-.893zm9.724 0c-1.178 0-1.456-.177-1.456-.93v-7.984a17.49 17.49 0 0 1-.253.382 17.002 17.002 0 0 1-2.113 2.502v5.1c0 .753-.277.93-1.456.93h-.885v.372h7.048v-.372h-.885zM20.813 9.161a18.513 18.513 0 0 0 .003.152l.005.18.002.082v.11l-.009.601a12.4 12.4 0 0 1-.042.805c-.05.593-.15 1.29-.318 2.03a16.088 16.088 0 0 1-.702 2.293 16.56 16.56 0 0 1-1.052 2.202 17.217 17.217 0 0 1-1.737 2.496 17.104 17.104 0 0 1-.74.839l-.107.113h3.524v-.371h-2.95l.001-.002.061-.049.129-.105a18.06 18.06 0 0 0 .956-.843 17.931 17.931 0 0 0 4.236-6.093c.325-.772.571-1.518.746-2.173.087-.327.158-.631.212-.905a5.991 5.991 0 0 0 .108-.726l.011-.15c.004-.05.006-.094.008-.15l.005-.184.002-.113v-.039h-2.352z" fill="#B48B72"/>
                </g>
              </svg>
            </Pin>

            {activePinId ? (
              <Pin lat={tr('pins').find(pin => pin.id === activePinId).lat} lng={tr('pins').find(pin => pin.id === activePinId).lng}>
                <svg viewBox="0 0 27 34" width="27" height="34">
                  <g stroke="#231F20" strokeWidth=".5" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                    <path fill="#FFF" d="M13.862 33.237L9.036 24.5l8.928-.31z"/>
                    <path d="M1 13.45C1 6.573 6.597 1 13.5 1S26 6.574 26 13.45c0 6.875-5.597 12.449-12.5 12.449S1 20.325 1 13.449z" fill="#FFF"/>
                    <path d="M5.464 13.463c0-4.42 3.598-8.004 8.036-8.004s8.036 3.583 8.036 8.004c0 4.42-3.598 8.003-8.036 8.003s-8.036-3.583-8.036-8.003z"/>
                  </g>
                </svg>
              </Pin>
            ) : null}
        </GoogleMapReact>
      </Body>
    </Container>
  </Root>
  </Layout>
);

export const enhance = compose(
  withState('center', 'onCenterChange', {lat: 55.7285, lng: 37.5652063}),
  withState('activePinId', 'onPinChange', null),
  withState('activeRadio', 'onRadioChange', 'all'),
  withState('activeView', 'onViewChange', 'map'),
  HOC.withTranslation(data),
  onlyUpdateForPropTypes,
  setPropTypes({
    activeView: T.oneOf(['map', 'list']).isRequired,
    activePinId: T.number,
    activeRadio: T.string.isRequired,
    center: T.shape({
      lat: T.number.isRequired,
      lng: T.number.isRequired,
    }).isRequired,
    tr: T.func.isRequired,
    onPinChange: T.func.isRequired,
    onRadioChange: T.func.isRequired,
    onCenterChange: T.func.isRequired,
    onViewChange: T.func.isRequired,
  }),
);

export default enhance(Map);
